<!--带左侧菜单的框架-->
<template>
  <div id="menuIndex" @click="menuIndexClick">
    <div class="menuIndex_left">
      <ModuleMenu
        v-if="menuP"
        :menuData="menuData"
        :active="menuActive"
        @menuFun="menuClickFun"
      ></ModuleMenu>
    </div>
    <div class="menuIndex_right">
      <div id="nav">
        <div class="nav_head">
          <el-container class="head_box">
            <el-main class="head_left" style="padding-top: 10px !important">
              <div v-for="(item, index) in tabData" :key="index">
                <div
                  class="tab_list"
                  :id="item.menuid"
                  :title="item.menuname"
                  @click="tabClickFun(item, index)"
                  @contextmenu.prevent="rightClickFun(item, index)"
                >
                  <span class="tab_icon iconfont" :class="item.icon"></span>
                  <p class="tab_txt">{{ item.menuname }}</p>
                  <span
                    v-if="tabData.length > 1"
                    class="tab_close iconfont iconguanbi"
                    title="关闭"
                    @click.stop="tabCloseFun(item, index)"
                  ></span>
                </div>
              </div>
            </el-main>
            <el-aside class="head_right">
              <!-- <div class="p_name" v-if="pName">{{pollutionName}}</div> -->
              <div
                class="go_back iconfont iconfanhui"
                v-if="backP"
                title="返回"
                @click="backClickFun"
              ></div>
              <div
                class="refresh iconfont iconshuaxin"
                title="刷新"
                @click="refreshClickFun"
              ></div>
            </el-aside>
          </el-container>
        </div>
        <div class="nav_box">
          <!-- :include="" -->
          <keep-alive include="commonRegionList">
            <router-view ref="leftrouter"></router-view>
          </keep-alive>
        </div>
      </div>
    </div>
    <!--tab右击弹窗-->
    <div id="tabRight" @click.stop>
      <div class="tabRight_list" @click="closeClickFun">
        <span class="iconfont iconguanbi1" style="color: #909399"></span>
        <p>关闭当前选项卡</p>
      </div>
      <div class="tabRight_list" @click="closeElseClickFun">
        <span
          class="iconfont iconweimingming-zhuanhuan"
          style="color: #e6a23c; font-size: 16px"
        ></span>
        <p>关闭其他选项卡</p>
      </div>
      <div class="tabRight_list" @click="refreshClickFun('tab')">
        <span class="iconfont iconshuaxin" style="color: #67c23a"></span>
        <p>刷新当前选项卡</p>
      </div>
    </div>
    <!--错误组件-->
    <!-- <ModuleError :errorData="errorData"></ModuleError> -->
  </div>
</template>
<!--
浏览器刷新路由不变
1.记住当前菜单
sessionStorage.setItem("menuActive",that.menuActive);
2.记住现有的tab数据
sessionStorage.setItem("tabData",JSON.stringify(that.tabData));
3.缓存的页面
sessionStorage.setItem("includeArr", JSON.stringify(that.includeArr));
-->
<script>
import ModuleMenu from "../common/module/ModuleMenu";
import configBasics from "../utils/configBasics";
import ModuleError from "../common/module/ModuleError";

import { mapState } from "vuex";
export default {
  name: "leftMenuIndex",
  components: { ModuleMenu, ModuleError },
  data() {
    return {
      /*污染源名称*/
      pName: false,
      pollutionName: "",
      /*返回按钮*/
      backP: false,
      backData: {
        url: "",
        query: "",
        name: ""
      },
      /*菜单*/
      menuZDP: false,
      menuActive: "",
      menuData: [],
      menuP: true,
      /*面包屑*/
      tabData: [],
      menuidData: [],
      tabIndexData: {},
      tabIndex: "",
      /*缓存*/
      includeArr: ["app"],
      includeStr: "app",
      /*错误组件*/
      errorData: {
        isError: false,
        showImg: true,
        errorType: "",
        expires: "",
        time: new Date().getTime()
      },
      initSysmodel: "",
      initSuiji: ""
    };
  },
  created() {
    let that = this;
    this.initSysmodel = this.$route.query.sysmodel;
    this.initSuiji = this.$route.query.suiji;
    /*判断是否有pollutionId*/
    // console.log(this.$route.query)
    that.backP = false;
    that.backData = {
      url: "",
      query: "",
      name: ""
    };
    that.pName = false;
    /*获取子菜单*/
    let { moduleName, isFromBackStage, menuid } = this.$route.query;
    let params = {
      moduleName,
      isFromBackStage,
      menuid,
      appId: sessionStorage.getItem("appId")
        ? sessionStorage.getItem("appId")
        : "app1"
    };
    that.menuFun(params, true);
  },
  computed: {
    ...mapState({
      yjLeftMenuData: state => state.menus.yjLeftMenuData
    })
  },
  // watch:{
  //   yjLeftMenuData: {
  //     handler(val) {
  //       console.log(val)
  //     },
  //     immediate: true
  //   }
  // },
  methods: {
    /*折叠事件*/
    menuZDClickFun(params) {
      let that = this;
      that.menuZDP = params;
      if (that.menuZDP) {
        $("#menuIndex .menuIndex_left").animate(
          {
            width: "60px"
          },
          300
        );
        $("#menuIndex .menuIndex_right").animate(
          {
            "padding-left": "60px"
          },
          300
        );
      } else {
        $("#menuIndex .menuIndex_left").animate(
          {
            width: "238px"
          },
          300
        );
        $("#menuIndex .menuIndex_right").animate(
          {
            "padding-left": "238px"
          },
          300
        );
      }
    },
    /*菜单点击事件*/
    menuClickFun(item) {
      let that = this;
      console.log(item, "菜单点击事件");
      sessionStorage.setItem("sysmodel", item.sysmodel);
      /*删除相应页面的缓存*/
      for (let i = 0; i < that.includeArr.length; i++) {
        if (that.includeArr[i] === item.pagemark) {
          that.includeArr.splice(i, 1);
          break;
        }
      }
      that.includeStr = that.includeArr.join(",");
      console.log(JSON.stringify(that.includeArr), "删除完以后缓存的页面");
      /*选中的菜单*/
      that.menuActive = item.menuid;
      console.log(that.menuActive, 11111);
      sessionStorage.setItem("menuActive", that.menuActive);
      /*获取tab数据*/
      if (that.menuidData.indexOf(item.menuid) === -1) {
        that.menuidData.push(item.menuid);
        that.tabData.push(item);
        sessionStorage.setItem("tabData", JSON.stringify(that.tabData));
      }
      /*需要缓存的页面*/
      if (that.includeArr.indexOf(item.pagemark) === -1) {
        that.includeArr.push(item.pagemark);
        that.includeStr = that.includeArr.join(",");
        sessionStorage.setItem("includeArr", JSON.stringify(that.includeArr));
      }
      /*改变tab选中*/
      that.tabActiveFun(item);
      /*跳转页面*/
      console.log(item);
      if (item.navigateurl) {
        let censusObj = {
          systemcode: this.VUE_APP_CODE, //访问系统代码
          systemname: this.VUE_APP_NAME, //访问系统名称
          menuid: item.menuid,
          menuname: item.menuname,
          visittime: +new Date(),
          visittype: "menu"
        };
        if (sessionStorage.getItem("dfName")) {
          censusObj.systemcode = this.VUE_APP_DF_CODE;
          censusObj.systemname = this.VUE_APP_DF_NAME;
        }
        if (item.target === "1") {
          that.$router.push({
            path: "/index/leftMenuIndex/iframePage",
            query: {
              url: item.navigateurl
            }
          });
        } else {
          let queryData = {};
          queryData["ppid"] = that.$route.query.ppid;
          queryData["menuid"] = item.menuid;
          queryData["sysmodel"] = item.sysmodel;
          if (item.moduleName) {
            queryData["moduleName"] = item.moduleName;
          }
          if (that.$route.query.pollutionId) {
            queryData["pollutionId"] = that.$route.query.pollutionId;
          }
          queryData["suiji"] = that.$route.query.suiji;
          that.$router.push({
            ...this.$route.query,
            path: item.navigateurl,
            query: queryData
          });
        }
      } else {
        that.$router.push({
          path: "/index/leftMenuIndex/404"
        });
      }
      /*需要缓存的页面*/
      that.$nextTick(function () {
        if (that.includeArr.indexOf(item.pagemark) === -1) {
          that.includeArr.push(item.pagemark);
          that.includeStr = that.includeArr.join(",");
        }
        console.log(that.includeArr, "缓存的页面");
      });
    },
    /*面包导航-点击事件*/
    tabClickFun(item, index) {
      let that = this;
      console.log(item, index, "面包导航-点击事件");
      /*菜单选中*/
      that.menuActive = item.menuid;
      sessionStorage.setItem("menuActive", that.menuActive);
      /*改变tab的选中*/
      that.tabActiveFun(item);
      /*跳转页面*/

      if (item.navigateurl) {
        if (item.target === "1") {
          that.$router.push({
            path: "/index/leftMenuIndex/iframePage",
            query: {
              url: item.navigateurl
            }
          });
        } else {
          let queryData = {};
          queryData["ppid"] = that.$route.query.ppid;
          queryData["menuid"] = item.menuid;
          queryData["sysmodel"] = item.sysmodel;
          if (that.$route.query.pollutionId) {
            queryData["pollutionId"] = that.$route.query.pollutionId;
          }
          that.$router.push({
            path: item.navigateurl,
            query: queryData
          });
        }
      } else {
        that.$router.push({
          path: "/index/leftMenuIndex/404"
        });
      }
    },
    /*改变tab的选中样式*/
    tabActiveFun(item) {
      let that = this;
      that.$nextTick(function () {
        $("#nav .tab_list").removeClass("active");
        $("#nav #" + item.menuid).addClass("active");
      });
    },
    /*面包导航-单个关闭事件*/
    tabCloseFun(item, index) {
      let that = this;
      console.log(item, index, "面包导航-单个关闭事件");
      /*获取当前数据*/
      that.tabIndexData = item;
      that.tabIndex = index;
      that.closeClickFun(item);
    },
    /*面包导航-鼠标右击事件*/
    rightClickFun(item, index) {
      let that = this;
      console.log(item, index, "面包导航-鼠标右击事件");
      /*定位*/
      let top = $("#nav .tab_list").eq(index).offset().top;
      let left = $("#nav .tab_list").eq(index).offset().left;
      $("#tabRight").show();
      $("#tabRight").css({
        top: top + 30,
        left: left
      });
      /*获取当前数据*/
      that.tabIndexData = item;
      that.tabIndex = index;
    },
    /*关闭面包导航弹窗*/
    menuIndexClick() {
      $("#tabRight").hide();
    },
    /*返回的点击事件*/
    backClickFun() {
      let that = this;
      // return console.log(that.backData.url, 123456)
      that.$router.push({
        path: that.backData.url,
        query: that.backData.query
      });
      sessionStorage.removeItem("tabData");
      sessionStorage.removeItem("includeArr");
      sessionStorage.removeItem("menuActive");
      // return
      /*传递菜单信息-获取菜单*/
      that.BUS.$emit("topMenuFun", {
        menuid: that.backData.query.ppid
      });
      /*清空*/
      that.backP = false;
      that.backData = {
        url: "",
        query: "",
        name: ""
      };
      that.pName = false;
    },
    /*刷新的点击事件*/
    refreshClickFun(p) {
      let that = this;
      let pageName;
      if (p === "tab") {
        console.log(that.tabIndexData, "当前右击当前tab页数据");
        /*刷新tab的某个标签页*/
        pageName = that.tabIndexData.pagemark;
      } else {
        /*刷新当前页面*/
        pageName = that.$route.name;
      }
      /*传递信息使页面刷新*/
      that.BUS.$emit("refreshFun");
      that.BUS.$emit(pageName, pageName);
      /*关闭面包导航弹窗*/
      that.menuIndexClick();
    },
    /*关闭当前选项卡的点击事件*/
    closeClickFun(item) {
      let that = this;
      console.log(that.tabIndexData, that.tabIndex, "关闭当前选项卡的点击事件");
      /*去除相应缓存的页面*/
      for (let i = 0; i < that.includeArr.length; i++) {
        if (that.includeArr[i] === that.tabIndexData.pagemark) {
          that.includeArr.splice(i, 1);
          break;
        }
      }
      that.includeStr = that.includeArr.join(",");
      sessionStorage.setItem("includeArr", JSON.stringify(that.includeArr));
      /*关闭tab标签相关操作*/
      if (that.tabData.length <= 1) {
        that.$message({
          message: "该选项卡不可关闭！",
          type: "warning"
        });
      } else {
        /*删除相应数据*/
        that.menuidData.splice(that.tabIndex, 1);
        that.tabData.splice(that.tabIndex, 1);
        sessionStorage.setItem("tabData", JSON.stringify(that.tabData));
        /*关闭弹窗*/
        that.menuIndexClick();
        /*判断当前tab时候是选中*/
        let ind;
        for (let i = 0; i < $("#nav .tab_list").length; i++) {
          let s = $("#nav .tab_list").eq(i).attr("class").split(" ");
          if (s.indexOf("active") !== -1) {
            /*存在*/
            ind = i;
            break;
          }
        }
        /*判断关闭的tab是选中的还是不是选中的*/
        if (that.tabIndex === ind) {
          /*关闭的是当前选中的*/
          let index;
          if (that.tabIndex <= 0) {
            /*关闭的事第一个*/
            index = that.tabIndex;
          } else {
            index = that.tabIndex - 1;
          }
          that.$nextTick(function () {
            /*菜单选中*/
            that.menuActive = that.tabData[index].menuid;
            /*tab样式*/
            $("#nav .tab_list").removeClass("active");
            $("#nav .tab_list").eq(index).addClass("active");
          });
          /*跳转页面*/
          if (that.tabData[index].navigateurl) {
            if (that.tabData[index].target === "1") {
              that.$router.push({
                path: "/index/leftMenuIndex/iframePage",
                query: {
                  url: that.tabData[index].navigateurl
                }
              });
            } else {
              let queryData = {};
              queryData["ppid"] = that.$route.query.ppid;
              queryData["menuid"] = that.tabData[index].menuid;
              queryData["sysmodel"] = that.tabData[index].sysmodel;
              if (that.$route.query.pollutionId) {
                queryData["pollutionId"] = that.$route.query.pollutionId;
              }
              that.$router.push({
                path: that.tabData[index].navigateurl,
                query: queryData
              });
            }
          } else {
            that.$router.push({
              path: "/index/leftMenuIndex/404"
            });
          }
        } else if (that.tabIndex < ind) {
          /*关闭的是当前选中的前面的*/
          that.$nextTick(function () {
            /*菜单选中*/
            that.menuActive = that.tabData[ind - 1].menuid;
            /*tab样式*/
            $("#nav .tab_list").removeClass("active");
            $("#nav .tab_list")
              .eq(ind - 1)
              .addClass("active");
          });
        }
      }
    },
    /*关闭其他选项卡的点击事件*/
    closeElseClickFun() {
      let that = this;
      console.log(that.tabIndexData, that.tabIndex, "关闭其他选项卡的点击事件");
      /*去除相应缓存的页面*/
      that.includeArr = ["app"];
      that.includeArr.push(that.tabData[0].pagemark);
      if (that.includeArr.indexOf(that.tabIndexData.pagemark) === -1) {
        that.includeArr.push(that.tabIndexData.pagemark);
      }
      that.includeStr = that.includeArr.join(",");
      sessionStorage.setItem("includeArr", JSON.stringify(that.includeArr));
      /*关闭tab标签相关操作*/
      if (that.tabData.length > 1) {
        /*删除相应数据*/
        that.menuidData.splice(that.tabIndex + 1);
        that.tabData.splice(that.tabIndex + 1);
        that.menuidData.reverse().splice(1, that.menuidData.length - 1);
        that.tabData.reverse().splice(1, that.tabData.length - 1);
        that.menuidData.reverse();
        that.tabData.reverse();
        sessionStorage.setItem("tabData", JSON.stringify(that.tabData));
        that.$nextTick(function () {
          /*菜单选中*/
          that.menuActive = that.tabData[0].menuid;
          /*tab样式*/
          $("#nav .tab_list").removeClass("active");
          $("#nav .tab_list").eq(0).addClass("active");
        });
        /*关闭弹窗*/
        that.menuIndexClick();
        /*跳转页面*/
        if (that.tabData[0].navigateurl) {
          if (that.tabData[0].target === "1") {
            that.$router.push({
              path: "/index/leftMenuIndex/iframePage",
              query: {
                url: that.tabData[0].navigateurl
              }
            });
          } else {
            let queryData = {};
            queryData["ppid"] = that.$route.query.ppid;
            queryData["menuid"] = that.tabData[0].menuid;
            queryData["sysmodel"] = that.tabData[0].sysmodel;
            if (that.$route.query.pollutionId) {
              queryData["pollutionId"] = that.$route.query.pollutionId;
            }
            that.$router.push({
              path: that.tabData[0].navigateurl,
              query: queryData
            });
          }
        } else {
          that.$router.push({
            path: "/index/leftMenuIndex/404"
          });
        }
      } else {
        that.$message({
          message: "无选项卡可关闭！",
          type: "warning"
        });
      }
    },
    /*获取菜单数据*/
    menuFun(params, flag) {
      console.log(this.yjLeftMenuData);
      //左侧菜单数据若不是后台请求过来的，就不走接口去获取
      let { isFromBackStage, moduleName } = window.sessionStorage;
      this.menuP = false;
      this.menuData =
        this.yjLeftMenuData[
          this.$route.query.sysmodel || sessionStorage.getItem("value_sysmodel")
        ];
      // 重新加载ModuleMenu组件，否则连续点击不同顶部菜单下的左侧菜单时，第二次点击的左侧菜单高亮显示会有问题
      this.$nextTick(()=>{
        this.menuP = true;
      })
      console.log(this.menuData);
      if (flag) {
        sessionStorage.removeItem("menuActive");
      }
      // return false;
      return this.initMenuDataAfterClick(this.menuData);
    },
    setMenu(
      {
        // MODULENAME: menuname,
        MODULETITLE: menuName,
        PICURL: icon,
        ACCESSURL: navigateurl,
        DESCRIPTION: menuid,
        children = []
      },
      sysmodel
    ) {
      return Object.assign(
        {
          menuname,
          icon,
          menuid,
          sysmodel,
          moduleName: sysmodel
        },
        !!children.length && {
          children: children.map(item => this.setMenu(item, sysmodel))
        },
        navigateurl && { navigateurl }
      );
    },
    /* 判断跳转的是哪个页面 */
    roundFun(data) {
      let returnObj = null;
      data.map(item => {
        if (item.navigateurl && item.navigateurl == this.$route.path) {
          returnObj = item;
          return false;
        } else {
          if (item.children) {
            this.roundFun(item.children);
          }
        }
      });
      if (returnObj) {
        return returnObj;
      } else {
      }
    },

    initMenuDataAfterClick(dataList) {
      let that = this;
      that.tabData = [];
      that.menuidData = [];
      that.includeArr = ["app"];
      that.includeStr = that.includeArr.join(",");
      if (this.$route.query.menuid) {
        //解决刷新时默认第一个的Bug
        sessionStorage.setItem("menuActive", this.$route.query.menuid);
      }
      if (sessionStorage.getItem("menuActive")) {
        /*激活的菜单记录的数据*/
        let menuActive = sessionStorage.getItem("menuActive");
        that.menuActive = menuActive;
        /*tab记录的数据*/
        let tabData = JSON.parse(sessionStorage.getItem("tabData"));
        that.tabData = tabData;
        for (let i = 0; i < tabData.length; i++) {
          that.menuidData.push(tabData[i].menuid);
        }
        /*缓存页面记录的数据*/
        let includeArr = JSON.parse(sessionStorage.getItem("includeArr"));
        that.includeArr = includeArr;
        that.includeStr = that.includeArr.join(",");
        /*tab选中*/
        that.tabActiveFun({ menuid: menuActive });
      } else {
        /*获取第一个菜单*/
        console.log(dataList);
        let { mo } = this.$route.query;
        let childrenMenuDate = that.childrenMenuFun([dataList[0]]);
        if (mo) {
          childrenMenuDate = this.getMenuById(mo);
        }

        this.initSysmodel = this.$route.query.sysmodel;
        this.initSuiji = this.$route.query.suiji;
        console.log(that.$route.query);
        console.log(childrenMenuDate, "获取第一个菜单");
        // return false
        /*跳转第一个菜单*/
        if (childrenMenuDate.navigateurl) {
          let censusObj = {
            systemcode: this.VUE_APP_CODE, //访问系统代码
            systemname: this.VUE_APP_NAME, //访问系统名称
            menuid: childrenMenuDate.menuid,
            menuname: childrenMenuDate.menuname,
            visittime: +new Date(),
            visittype: "menu"
          };
          if (sessionStorage.getItem("dfName")) {
            censusObj.systemcode = this.VUE_APP_DF_CODE;
            censusObj.systemname = this.VUE_APP_DF_NAME;
          }
          that.$router.push({
            path: childrenMenuDate.navigateurl,
            query: {
              menuid: childrenMenuDate.menuid,
              sysmodel: childrenMenuDate.sysmodel,
              suiji: that.$route.query.suiji
            }
          });
        } else {
          that.$router.push({
            path: "/index/leftMenuIndex/404"
          });
        }

        that.$nextTick(function () {
          /*面包屑-赋值*/
          that.tabData.push(childrenMenuDate);
          that.menuidData.push(childrenMenuDate.menuid);
          sessionStorage.setItem("tabData", JSON.stringify(that.tabData));
          /*缓存-赋值*/
          that.includeArr.push(childrenMenuDate.pagemark);
          that.includeStr = that.includeArr.join(",");
          sessionStorage.setItem("includeArr", JSON.stringify(that.includeArr));
          setTimeout(function () {
            /*默认第一个菜单*/
            that.menuActive = childrenMenuDate.menuid;
            sessionStorage.setItem("menuActive", that.menuActive);
            /*默认第一个选中tab*/
            $("#nav .tab_list").eq(0).addClass("active");

            // setTimeout(function() {
            //   if ($('.el-submenu.is-active.is-opened').length === 0) {
            //     $('.el-submenu').eq(0).click()
            //     if ( $('.el-menu.el-menu--inline').eq(0).css('display') === 'none' ) {
            //       $('.el-submenu__icon-arrow.el-icon-arrow-down').eq(0).click()
            //     }
            //     $('.el-menu-item').eq(0).click()
            //   }
            // }, 100)
          }, 300);
        });
      }
    },
    getMenuById(id) {
      let node = "";
      function test1(data, id) {
        data.find(({ menuid, children }, index) => {
          if (!!node) {
            return true;
          }
          if (menuid === id) {
            return (node = data[index]);
          }
          if (children && children.length) {
            test1(children, id);
          }
        });
      }
      test1(this.menuData, id);
      return node;
    },
    /*查找第一个我子菜单*/
    childrenMenuFun(data) {
      let listData = [];
      function dataFun(data) {
        for (let i = 0; i < data.length; i++) {
          if (listData.length === 0) {
            if (data[0].children && data[0].children.length !== 0) {
              dataFun(data[0].children);
            } else {
              listData = data[0];
              break;
            }
          } else {
            break;
          }
        }
      }
      dataFun(data);
      return listData;
    },
    // 查找需要选的对象

    /*错误组件*/
    errorFun(isError, showImg, errorType, expires) {
      /*开关-是否图片展示错误-展示的类型-过期操作*/
      this.errorData.isError = isError;
      this.errorData.showImg = showImg;
      this.errorData.errorType = errorType;
      this.errorData.expires = expires;
      this.errorData.time = new Date().getTime();
    }
  },
  mounted() {
    let that = this;
    /*折叠初始化*/
    that.menuZDClickFun(eval(sessionStorage.getItem("menuZDP")));
    /*监听折叠事件*/
    that.BUS.$on("menuZDCFun", function (params) {
      that.menuZDClickFun(params);
    });
    /*监听父菜单点击事件*/
    that.BUS.$on("topMenuFun", function (params) {
      that.menuFun(params);
    });
  },
  watch: {
    $route({ name, query }, from) {
      if (query.sysmodel != this.initSysmodel || query.menuClick) {
        // console.log("重新获取左侧菜单")
        this.menuFun("", true);
      }
      if (
        query.sysmodel == this.initSysmodel &&
        query.suiji != this.initSuiji
      ) {
        console.log("6---");
        //头部菜单重复点击当前的左侧菜单
        this.menuFun("", true);
      }
      try {
        let table = this.$refs.leftrouter.$refs.mytable;
        if (!table) return;
        let { mytable } = table.$refs;
        this.$nextTick(() => {
          table.$nextTick(() => {
            mytable.doLayout();
          });
        });
      } catch (e) {}
    }
  },
  beforeRouteUpdate(to, { name }, next) {
    next();
  },
  beforeDestroy() {
    let that = this;
    sessionStorage.removeItem("menuActive");
    that.BUS.$off("menuZDCFun");
    that.BUS.$off("topMenuFun");
  }
};
</script>

<style scoped>
#menuIndex {
  width: 100%;
  height: 100%;
  position: relative;
}

#menuIndex .menuIndex_left {
  width: 238px;
  /* height: 96%; */
  background: #fff;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  bottom: 25px;
  box-shadow: 1px 0 8px -3px #c6c5ca;
  height: 100%;
}

#menuIndex .menuIndex_right {
  width: 100%;
  height: 100%;
  padding-left: 238px;
  overflow: hidden;
  background: #f0f3fa;
  background: #fff;
}

#nav {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 48px;
}

/*面包屑*/
#nav .nav_head {
  width: 100%;
  height: 48px;
  overflow: hidden;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 8px -3px #c6c5ca;
}

.nav_head .head_box {
  width: 100%;
  height: 100%;
  background: #f0f3fa;
}

/*面包屑-列表*/
.nav_head .head_left {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  padding: 12px 0 0 2px;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
}

.head_left .tab_list {
  height: 100%;
  line-height: 34px;
  padding: 0 18px 0 12px;
  border-right: 1px solid #dddddd;
  border-top: 1px solid transparent;
  cursor: pointer;
  min-width: 46px;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
}

.head_left .tab_list:first-child {
  border-left: 1px solid #dddddd;
}

.head_left .tab_list.active,
.head_left .tab_list:hover {
  color: #000000;
  background: #edf2f8;
  border-color: #dddddd;
}

.tab_list .tab_txt {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tab_list .tab_icon {
  display: block;
  width: 30px;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.tab_list .tab_close {
  font-size: 16px;
  color: #d1d5d9;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.tab_list .tab_close:hover {
  color: red;
}

/*面包屑-刷新-返回*/
.nav_head .head_right {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  width: auto !important;
  padding-left: 12px;
}

.head_right .p_name {
  height: 100%;
  float: left;
  padding-right: 12px;
  color: #e6a23c;
}

.head_right .go_back,
.head_right .refresh {
  float: left;
  padding: 0 2px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  border: 1px solid #dddddd;
  margin-right: 12px;
  color: #959595;
  border-radius: 2px;
}

.head_right .go_back:hover {
  color: #0e90d2;
  border-color: #0e90d2;
  /* font-weight: bold; */
}

.head_right .refresh:hover {
  color: #67c23a;
  border-color: #67c23a;
  font-weight: bold;
}

/*tab右击弹窗*/
#tabRight {
  display: none;
  color: rgb(72, 87, 106);
  width: 160px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0 0 4px #ccc;
  padding: 0 6px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

#tabRight .tabRight_list {
  border-bottom: 1px solid #dddddd;
  line-height: 36px;
  cursor: pointer;
}

#tabRight .tabRight_list:hover {
  font-weight: bold;
}

#tabRight .tabRight_list:hover p {
  font-weight: bold;
}

#tabRight .tabRight_list:last-child {
  border-bottom: none;
}

#tabRight .tabRight_list span {
  display: inline-block;
  margin-right: 6px;
}

#tabRight .tabRight_list p {
  display: inline-block;
}

/*主体*/
#nav .nav_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
