<!--错误页面组件-WXW-->
<template>
  <div id="error" v-if="errorData.isError">
    <!--请求资源出错-->
    <div v-if="errorData.errorType==='order' && errorData.showImg" id="order">
      <img class="order_img" src="../../assets/img/resource_error.jpg" alt="请求资源出错">
      <p class="order_txt">请求资源出错，请刷新页面！</p>
    </div>
    <!--系统故障-->
    <div v-if="errorData.errorType==='resource'&& errorData.showImg" id="resource">
      <img class="resource_img" src="../../assets/img/out_of_order.jpg" alt="请求资源出错">
      <p class="resource_txt">系统故障，请联系管理员！</p>
    </div>
  </div>
</template>
<!--
  传递的参数
  errorData:{
     isError:开关（true/false）
     showImg: 是否以图片形式展示（true/false）
     errorType: 展示的错误类型（order、resource）,
     expires: 判断过期类型,
     time: new Date().getTime()
  }
  引用组件的父级加定位
-->
<script>
  export default {
    name: "ModuleError",
    props: ['errorData'],
    data() {
      return {
        expiresArr: ["nosession", "manyuser", "manysession"]
      }
    },
    created() {
      let that = this;
    },
    methods: {
      messageFun() {
        let that = this;
        console.log(that.errorData);
        if (that.errorData) {
          if (that.errorData.isError) {
            if (that.errorData.expires && that.expiresArr.indexOf(that.errorData.expires) !== -1) {
              //过期操作
              if (that.errorData.expires === "nosession") {
                that.$confirm('登录身份过期，请重新登录！', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  showClose: false,
                  showCancelButton: false,
                  closeOnClickModal: false
                }).then(function () {
                  that.outFun();
                });
              }
              else if (that.errorData.expires === "manyuser") {
                that.$confirm('当前浏览器有其他用户登录，请重新登录！', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  showClose: false,
                  showCancelButton: false,
                  closeOnClickModal: false
                }).then(function () {
                  that.outFun();
                });
              }
              else if (that.errorData.expires === "manysession") {
                that.$confirm('当前用户在其他地方登录，您被迫下线！', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  showClose: false,
                  showCancelButton: false,
                  closeOnClickModal: false
                }).then(function () {
                  that.outFun();
                });
              }
            } else {
              //已提示框展示
              if (!that.errorData.showImg) {
                if (that.errorData.errorType === "order") {
                  that.$message({
                    showClose: true,
                    message: '请求资源出错，请刷新页面！',
                    type: 'error',
                    duration: 1000
                  });
                  that.errorData.isError = false;
                } else if (that.errorData.errorType === "resource") {
                  that.$message({
                    showClose: true,
                    message: '系统故障，请联系管理员！',
                    type: 'error',
                    duration: 1000
                  });
                  that.errorData.isError = false;
                }
              }
            }
          }
        }
      },
      /*退出操作*/
      outFun() {
        let that = this;
        /*删除所有的缓存*/
        sessionStorage.clear();
        localStorage.clear();
        /*跳转*/
        let routeData = that.$router.resolve({
          path: "/",
        });
        window.open(routeData.href, '_self');
        $(".el-message-box__wrapper,.v-modal").remove();
      }
    },
    mounted() {
      let that = this;
      that.messageFun();
    },
    watch: {
      errorData: {
        handler() {
          let that = this;
          that.messageFun();
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  #error {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  #order, #resource {
    background: #ffffff;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    padding-top: 5%;
  }

  #order .order_txt, #resource .resource_txt {
    padding-top: 30px;
    font-size: 36px;
  }

  #resource .resource_img, #order .order_img {
    max-width: 100%;
  }
</style>
