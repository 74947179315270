<!--多级菜单组件-配合导航菜单组件使用-WXW-->
<template>
  <div id="menuItem">
    <template v-for="item in menuData">
      <template v-if="item.datalistchildren && item.datalistchildren!=''">
        <el-submenu :index="item.menuid">
          <template slot="title">
            <i class="iconfont" :class="item.icon"></i>
            <span :title="item.menuname">{{item.menuname}}</span>
          </template>
          <MenuItem :menuData="item.datalistchildren" @menuFun="menuClickFun"></MenuItem>
        </el-submenu>
      </template>
      <template v-else>
        <el-menu-item :index="item.menuid" :title="item.menuname" @click="menuClickFun(item)">
          <i class="iconfont" :class="item.icon"></i>
          <span slot="title">
            {{item.menuname}}
            <template v-if="item.menucount && item.menucount>0 && item.menucount<=99">
              <span class="item_count">({{item.menucount}})</span>
            </template>
            <template v-if="item.menucount && item.menucount>99">
              <span class="item_count">(99+)</span>
            </template>
          </span>
        </el-menu-item>
      </template>
    </template>
  </div>

</template>
<!--
menuData：数组类型（二级以上菜单数据）
-->
<script>
  import MenuItem from './MenuItem'

  export default {
    name: "MenuItem",
    props: ['menuData'],
    components: {MenuItem},
    data() {
      return {}
    },
    methods: {
      /*菜单点击事件*/
      menuClickFun(item) {
        let that = this;
        that.$emit('menuFun', item);
      },
    }
  }
</script>

<style scoped>
  #menuItem .item_count {
    color: #f3653a;
    position: relative;
    top: -2px;
  }
</style>
