<!--导航菜单组件-WXW-->
<template>
  <div id="menu">
    <vue-scroll :ops="ops">
      <div id="menuNav">
        <el-menu class="el-menu-vertical-demo" :default-active="active" :collapse="isCollapse">
          <template v-for="item in menuData">
            <template v-if="item.children && item.children!=''">
              <el-submenu :index="item.menuid">
                <template slot="title">
                  <i class="iconfont" :class="item.icon"></i>
                  <span :title="item.menuname">{{item.menuname}}</span>
                </template>
                <MenuItem :menuData="item.children" @menuFun="menuClickFun"></MenuItem>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :index="item.menuid" :title="item.menuname" @click="menuClickFun(item)">
                <i class="iconfont" :class="item.icon"></i>
                <span slot="title">
            {{item.menuname}}
            <template v-if="item.menucount && item.menucount>0 && item.menucount<=99">
              <span class="item_count">({{item.menucount}})</span>
            </template>
            <template v-if="item.menucount && item.menucount>99">
              <span class="item_count">(99+)</span>
            </template>
          </span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
    </vue-scroll>
  </div>
</template>
<!--
menuData：数组类型（菜单数据）
active：默认选中的菜单（menuid）
-->
<script>
  import MenuItem from './MenuItem'
  import bus from '../../utils/bus'
  import configBasics from '../../utils/configBasics'

  export default {
    name: "ModuleMenu",
    props: ['menuData', 'active'],
    components: {MenuItem},
    data() {
      return {
        ops: configBasics.vueScrollOps,
        isCollapse: false
      }
    },
    methods: {
      /*菜单点击事件*/
      menuClickFun(item) {
        let that = this;
        that.$emit('menuFun', item);
      },
      /*折叠事件*/
      menuZDClickFun(params) {
        let that = this;
        that.isCollapse = params;
      }
    },
    mounted() {
      let that = this;
      /*折叠初始化*/
      that.menuZDClickFun(eval(sessionStorage.getItem("menuZDP")));
      /*监听折叠事件*/
      bus.$on('menuZDCFun', function (params) {
        that.menuZDClickFun(params);
      });
    }
  }
</script>

<style scoped>
  #menu {
    width: 100%;
    height: 100%;
  }

  #menu .item_count {
    color: #f3653a;
    position: relative;
    top: -2px;
  }
</style>
<style>
  #menuNav .el-menu {
    border: none;
    background: #ffffff;
  }
  #menuNav .el-submenu {
    /* background: rgb(248, 248, 248); */
  }

  #menuNav .el-menu .el-menu-item, #menuNav .el-menu .el-submenu__title {
    height: 48px;
    line-height: 44px;
    border-bottom: 1px solid #e8e9ea;
    border-left: 2px solid transparent;
    padding-right: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.65);
  }

  #menuNav .el-menu .el-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #menuNav > .el-menu > .el-menu-item {
    font-size: 14px;
  }

  #menuNav > .el-menu > .el-submenu > .el-submenu__title {
    font-size: 14px;
  }

  #menuNav .el-menu .el-submenu .el-menu-item {
    background: #ffffff;
  }

  #menuNav .el-menu .el-menu-item .iconfont {
    font-size: 16px;
  }

  /*#menuNav .el-menu .el-menu-item:hover, #menuNav .el-menu .is-active {
    border-bottom-color: #e8e9ea;
    background: rgb(232,241,251);
    color: #2B7DBC;
  }*/

  #menuNav .el-menu .el-menu-item:hover .iconfont {
    color: #ffffff;
  }

  /*二级标题选中*/
  /*#menuNav .el-submenu.is-active > .el-submenu__title, #menuNav .el-submenu .el-submenu__title:hover {
    border-bottom-color: #e8e9ea;
    color: #2B7DBC;
  }*/

  #menuNav .el-submenu .el-submenu__title:hover .iconfont, #menuNav .el-submenu.is-active > .el-submenu__title .iconfont {
    color: #ffffff !important;
  }

  /*箭头选中-划入*/
  #menuNav .el-submenu .el-submenu__title:hover .el-submenu__icon-arrow,
  #menuNav .el-submenu.is-active > .el-submenu__title > .el-submenu__icon-arrow {
    color: #ffffff;
  }

  /*二级菜单-划入-选中*/
  /*#menuNav .el-submenu .el-menu-item:hover, #menuNav .el-submenu.is-active .is-active {
    background: #eff2f9;
    border-left-color: #0b92ff;
    color: #2B7DBC;
  }

  #menuNav .el-submenu .el-menu-item:hover .iconfont, #menuNav .el-submenu.is-active .is-active > .iconfont {
    color: #0e90fe;
  }*/

  #menuNav .el-submenu .el-menu-item {
    border-bottom: none;
    font-size: 14px;
  }

  #menuNav .el-submenu .el-menu-item .iconfont {
    font-size: 14px;
  }

  #menuNav .el-submenu .el-menu-item:last-child {
    border-bottom: 1px solid #e8e9ea;
  }

  #menuNav .el-submenu__icon-arrow {
    right: 10px;
  }

  .el-menu--vertical {
    left: 56px !important;
  }

  /*.el-menu--vertical .el-menu-item:hover, .el-menu--vertical .el-menu-item.is-active {
    color: #0b92ff;
    background: #eff2f9;
  }*/
</style>
