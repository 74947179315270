// 功能配置文件
import Vue from "vue";
import store from "@/store/index";
import { CODE_SYSTEM_REGION } from "@/config/parametercode.config";
/* 滚动条vuescroll-配置 */
let vueScrollOps = {
  rail: {
    size: "6px"
  },
  bar: {
    background: "#ccc",
    onlyShowBarOnScroll: false,
    keepShow: false
  },
  scrollPanel: {
    scrollingX: false,
    scrollingY: true
  }
};
/* 滚动条nicescroll-配置 */
let niceScroll = {
  cursorcolor: "#ccc", // #CC0071 光标颜色
  cursoropacitymax: 1, // 改变不透明度非常光标处于活动状态（scrollabar“可见”状态），范围从1到0
  touchbehavior: false, // 使光标拖动滚动像在台式电脑触摸设备
  cursorwidth: "6px", // 像素光标的宽度
  cursorborder: "0", // 游标边框css定义
  cursorborderradius: "5px", // 以像素为光标边界半径
  autohidemode: "leave", // 是否隐藏滚动条
  hidecursordelay: false, // 设置滚动条淡出的延迟时间（毫秒）
  railpadding: { top: 0, right: 0, left: 0, bottom: 0 }, // 设置轨道的内间距
  sensitiverail: true, // 单击轨道产生滚动
  zindex: 8,
  horizrailenabled: true /* 水平滚动 */
};

/* echart-dataZoom配置 */
let dataZoomSlider = {
  type: "slider",
  height: 10,
  handleSize: 16,
  showDataShadow: false,
  handleIcon:
    "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
  bottom: 6,
  showDetail: false,
  startValue: null
};

function niceScrollFun(node, isX) {
  if (isX) {
    niceScroll.horizrailenabled = true;
  } else if (!isX) {
    niceScroll.horizrailenabled = false;
  } else {
    niceScroll.horizrailenabled = true;
  }
  node.niceScroll(niceScroll);
  node.getNiceScroll().resize();
  $(".nicescroll-cursors").hover(
    function () {
      $(".nicescroll-cursors").css("cursor", "pointer");
    },
    function () {
      $(".nicescroll-cursors").css("cursor", "default");
    }
  );
}
// 递归遍历
function changeTree(val) {
  let arr = [];
  if (val.length !== 0) {
    val.map(item => {
      let obj = {};
      obj.id = item.code;
      obj.label = item.name;
      if (item.node.length >= 1) {
        obj.children = changeTree(item.node);
      }
      arr.push(obj);
    });
  }
  return arr;
}

// 查看全省用户
function fkRegionList() {
  const systemparameterList =
    store.state.enterpriseReporting.Reporting.systemparameterList;
  return new Promise(function (resolve, reject) {
    let parentCode = "";
    systemparameterList.map(item => {
      if (item.parametercode === CODE_SYSTEM_REGION) {
        parentCode = item.parametervalue;
      }
    });
    // Vue.prototype.$api("common.getAllRegionTree").invoke({parentCode:"360000000000"}).then(res =>{
    Vue.prototype
      .$api("common.getAllRegionTree")
      .invoke({ parentCode })
      .then(res => {
        let treedata = [];
        treedata.push({
          id: changeTree(res)[0].id,
          label: changeTree(res)[0].label,
          children: changeTree(res)[0].children
        });
        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
// 查看全国用户
function fkRegionListAll() {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("common.getTreeRegion")
      .invoke()
      .then(({ data: { code, data } }) => {
        let treedata = [];
        treedata.push({
          id: changeListAll(data)[0].id,
          label: changeListAll(data)[0].label,
          children: changeListAll(data)[0].children
        });
        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
function getTreeRegionByParent(val) {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("common.getTreeRegionByParent")
      .invoke({ parentCode: val })
      .then(({ data: { code, data } }) => {
        let a = changeListAll(data);
        resolve(a);
      })
      .catch(res => {
        reject(res);
      });
  });
}

function changeListAll(val) {
  let arr = [];
  if (val.length !== 0) {
    val.map(item => {
      let obj = {};
      obj.id = item.id;
      obj.label = item.name;
      if (item.children && item.children.length >= 1) {
        obj.children = changeListAll(item.children);
      }
      arr.push(obj);
    });
  }
  return arr;
}
// 根据当前用户行政区划显示
function fkRegionListCode() {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("common.getAllRegionTree")
      .invoke({ parentCode: sessionStorage.getItem("regioncode") })
      .then(res => {
        let treedata = [];
        treedata.push({
          id: changeTree(res)[0].id,
          label: changeTree(res)[0].label,
          children: changeTree(res)[0].children
        });
        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
//递归遍历
function changeTreeNew(val) {
  let that = this;
  let arr = [];
  if (val.length !== 0) {
    val.map(item => {
      let obj = {};
      obj.id = item.CODE;
      obj.label = item.text;
      if (item.nodes && item.nodes.length >= 1) {
        obj.children = changeTreeNew(item.nodes);
      }
      arr.push(obj);
    });
  }
  return arr;
}
// 根据权限获取行政区划
function fkGetRegion() {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("common.getRegion")
      .invoke({ parentCode: sessionStorage.getItem("regioncode") })
      .then(res => {
        let treedata = [];
        // let data = res;//原来的
        let data = res.data.data;
        if (data.length == 0) {
          treedata = [];
        } else {
          treedata.push({
            id: changeTreeNew(data)[0].id,
            label: changeTreeNew(data)[0].label,
            children: changeTreeNew(data)[0].children
          });
        }

        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
// 获取河流列表树形
function getRiverTree() {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("integratedManagement.getRiverTree")
      .invoke({})
      .then(res => {
        let treedata = [];
        // let data = res;//原来的
        let data = res.data.data.data;
        if (data.length == 0) {
          treedata = [];
        } else {
          data.map((e, i) => {
            treedata.push({
              id: changeTreeNew(data)[i].id,
              label: changeTreeNew(data)[i].label,
              children: changeTreeNew(data)[i].children
            });
          });
        }

        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
// 获取行业树形
function findIndustrytype() {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("common.findIndustrytype")
      .invoke({})
      .then(res => {
        let treedata = [];
        // let data = res;//原来的
        let data = res.data.data;
        if (data.length == 0) {
          treedata = [];
        } else {
          data.map((e, i) => {
            treedata.push({
              id: changeTreeNew(data)[i].id,
              label: changeTreeNew(data)[i].label,
              children: changeTreeNew(data)[i].children
            });
          });
        }

        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
//递归遍历
function changeDepartTree(val) {
  let that = this;
  let arr = [];
  if (val.length !== 0) {
    val.map(item => {
      let obj = {};
      obj.id = item.PK_DEPARTID;
      obj.label = item.name;
      if (item.children && item.children.length >= 1) {
        obj.children = changeDepartTree(item.children);
      }
      arr.push(obj);
    });
  }
  return arr;
}
// 获取责任部门树形
function departmentTree() {
  return new Promise(function (resolve, reject) {
    Vue.prototype
      .$api("common.getOrgAllByTree")
      .invoke({})
      .then(res => {
        let treedata = [];
        // let data = res;//原来的
        let data = res.data.data.data;
        if (data.length == 0) {
          treedata = [];
        } else {
          data.map((e, i) => {
            treedata.push({
              id: changeDepartTree(data)[i].id,
              label: changeDepartTree(data)[i].label,
              children: changeDepartTree(data)[i].children
            });
          });
        }

        resolve(treedata);
      })
      .catch(res => {
        reject(res);
      });
  });
}
// 判断是否有权限
function jurisdiction(code) {
  if (code == "") {
    return true;
  } else {
    let childrenData = collData(
      JSON.parse(sessionStorage.getItem("treeList"))
    ).map(({ id }) => id);
    return childrenData.includes(code);
  }
}
// 判断是否有权限
function jurisdictions(code) {
  if (code == "") {
    return true;
  } else {
    let childrenData = collData(
      JSON.parse(sessionStorage.getItem("treeList"))
    ).map(({ id }) => id);
    let childrenDate = [];
    childrenData.map(item => {
      childrenDate.push(item.substring(0, 6));
    });
    return childrenDate.includes(code);
  }
}

function collData(_data, res = []) {
  _data.forEach(item => {
    res.push(item);
    if (item.children && item.children.length) {
      collData(item.children, res);
    }
  });
  return res;
}

/*加载阴影框-配置*/
let loading = {
  lock: true,
  text: "加载中...",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.3)"
};
/*开始日期范围限定*/
let startDateOps = {
  disabledDate(time) {
    return time.getTime() > Date.now() - 8.64e6;
  }
};
/*监测点类型配置*/
let monitoringPointOps = {
  /*废水监测点类型*/
  wasteWaterEnum: 1,
  /*废气监测点类型*/
  wasteGasEnum: 2,
  /*废气无组织监测点类型*/
  unOrganizationWasteGasEnum: 38,
  /*雨水监测点类型*/
  rainEnum: 37,
  /*大气监测点类型*/
  airEnum: 5,
  /*环境质量VOC监测点类型*/
  environmentalVocEnum: 10,
  /*环境质量恶臭监测点类型*/
  environmentalStinkEnum: 9,
  /*厂界小型站监测点类型*/
  factoryBoundarySmallStationEnum: 41,
  /*厂界恶臭监测点类型*/
  factoryBoundaryStinkEnum: 40
};
/*echarts颜色*/
let echartsColor = [
  "#00a0e9",
  "#19c760",
  "#f2c70c",
  "#5e4fe6",
  "#f19149",
  "#13b5b1",
  "#4777f5",
  "#ad46f3",
  "#e72f2f",
  "#8fc31f",
  "#cfa972",
  "#00feff",
  "#eb6100",
  "#1b67a6",
  "#f19ec2",
  "#19b4f9",
  "#b171d3",
  "#eb6877",
  "#26bd94",
  "#f67710"
];

export const weatherConfig = value => {
  let obj = {
    "icon-tianqi_lunkuoxian-1": "阴",
    "icon-tianqi_lunkuoxian-2": "阵雨",
    "icon-tianqi_lunkuoxian-3": "雷阵雨",
    "icon-tianqi_lunkuoxian-4": "雷阵雨伴有冰雹",
    "icon-tianqi_lunkuoxian-5": "雨夹雪",
    "icon-tianqi_lunkuoxian-6": "小雨",
    "icon-tianqi_lunkuoxian-7": "中雨",
    "icon-tianqi_lunkuoxian-8": "大雨",
    "icon-tianqi_lunkuoxian-9": "暴雨",
    "icon-tianqi_lunkuoxian-10": "大暴雨",
    "icon-tianqi_lunkuoxian-11": "特大暴雨",
    "icon-tianqi_lunkuoxian-12": "阵雪",
    "icon-tianqi_lunkuoxian-13": "小雪",
    "icon-tianqi_lunkuoxian-14": "中雪",
    "icon-tianqi_lunkuoxian-15": "大雪",
    "icon-tianqi_lunkuoxian-16": "暴雪",
    "icon-tianqi_lunkuoxian-17": "雾",
    "icon-tianqi_lunkuoxian-18": "冻雨",
    "icon-tianqi_lunkuoxian-19": "浮尘",
    "icon-tianqi_lunkuoxian-20": "扬沙",
    "icon-tianqi_lunkuoxian-21": "沙城暴",
    "icon-tianqi_lunkuoxian-22": "霾",
    "icon-tianqi_lunkuoxian-24": "多云",
    "icon-tianqi_lunkuoxian-25": "阵雪",
    "icon-tianqi_lunkuoxian-26": "阵雨",
    "icon-qing": "晴"
  };

  return (
    (/[\u4e00-\u9fa5]/.test(value)
      ? Object.keys(obj).find(item => value.includes(obj[item]))
      : obj[value]) || "icon-qing"
  );
};

export const numTransform = val => {
  let obj = {};
  if (val == "1") {
    obj = { class: "bgColor1", newval: "I" };
  } else if (val == "2") {
    obj = { class: "bgColor2", newval: "II" };
  } else if (val == "3") {
    obj = { class: "bgColor3", newval: "III" };
  } else if (val == "4") {
    obj = { class: "bgColor4", newval: "IV" };
  } else if (val == "5") {
    obj = { class: "bgColor5", newval: "V" };
  } else if (val == "6") {
    obj = { class: "bgColor6", newval: "劣V" };
  } else {
    obj = { class: "noColor", newval: "" };
  }
  return obj;
};
// 千位分隔符
export const separation = val => {
  let valFin = "";
  // console.log(val);
  if (val === "" || val === null || val === undefined) {
    return "--";
  } else if (val === 0) {
    return 0;
  } else {
    let separatingSymbol = ","; //分隔符
    valFin = String(val).split(".");
    for (let i = 0; i < valFin.length; i++) {
      valFin[i] = valFin[i].replace(
        new RegExp("(\\d)(?=(\\d{3})+$)", "ig"),
        `$1${separatingSymbol || ""}`
      );
    }
    return valFin.join(".");
  }
};

//根据传入的类  返回对应的颜色
let envClsObj = cls => {
  var obj = {};
  if (cls == "Ⅰ类") {
    obj = {
      oldval: cls,
      newval: "I类",
      waterColor: envColor.waterColor.waterI,
      gasColor: envColor.gasColor.gasI,
      envlevel: 1
    };
  } else if (cls == "Ⅱ类") {
    obj = {
      oldval: cls,
      newval: "I类",
      waterColor: envColor.waterColor.waterII,
      gasColor: envColor.gasColor.gasII,
      envlevel: 2
    };
  } else if (cls == "Ⅲ类") {
    obj = {
      oldval: cls,
      newval: "I类",
      waterColor: envColor.waterColor.waterIII,
      gasColor: envColor.gasColor.gasIII,
      envlevel: 3
    };
  } else if (cls == "Ⅳ类") {
    obj = {
      oldval: cls,
      newval: "I类",
      waterColor: envColor.waterColor.waterIV,
      gasColor: envColor.gasColor.gasIV,
      envlevel: 4
    };
  } else if (cls == "Ⅴ类") {
    obj = {
      oldval: cls,
      newval: "I类",
      waterColor: envColor.waterColor.waterV,
      gasColor: envColor.gasColor.gasV,
      envlevel: 5
    };
  } else if (cls == "Ⅵ类" || cls == "劣Ⅴ类") {
    obj = {
      oldval: cls,
      newval: "I类",
      waterColor: envColor.waterColor.waterVI,
      gasColor: envColor.gasColor.gasVI,
      envlevel: 6
    };
  } else {
    obj = {
      oldval: cls,
      newval: "无数据",
      waterColor: envColor.waterColor.water0,
      gasColor: envColor.gasColor.gas0,
      envlevel: 0
    };
  }
  return obj;
};
//颜色对象
export const envColor = {
  gasColor: {
    gasI: "#00E400",
    gasII: "#ffff00",
    gasIII: "#FF7E00",
    gasIV: "#FF0000",
    gasV: "#99004c",
    gasVI: "#7e0023",
    gas0: "#cccccc"
  },
  waterColor: {
    newwaterI: "#C5FFFF", //大屏幕颜色
    waterI: "#C5FFFF", //I类修改后的颜色
    waterII: "#1FCAF9",
    waterIII: "#00FF00",
    waterIV: "#FFFF00",
    waterV: "#FF9000",
    waterVI: "#FF0000",
    water0: "#cccccc"
  }
};
// 输入两组经纬度返回两组经纬度之间的距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10;
  return s;
}

function rgbToHex(r, g, b) {
  var hex = ((r << 16) | (g << 8) | b).toString(16);
  return "#" + new Array(Math.abs(hex.length - 7)).join("0") + hex;
}
// hex to rgb
function hexToRgb(hex) {
  var rgb = [];
  for (var i = 1; i < 7; i += 2) {
    rgb.push(parseInt("0x" + hex.slice(i, i + 2)));
  }
  return rgb;
}
// 计算两个颜色之间的渐变色值
function gradient(startColor, endColor, step) {
  var gradientColorArr = [];
  if (step === 1) {
    gradientColorArr.push(startColor);
  } else if (step > 1 && step < 3) {
    gradientColorArr.push(startColor, endColor);
  } else {
    // 将 hex 转换为rgb
    var sColor = hexToRgb(startColor),
      eColor = hexToRgb(endColor);
    // 计算R\G\B每一步的差值
    var rStep = (eColor[0] - sColor[0]) / step,
      gStep = (eColor[1] - sColor[1]) / step,
      bStep = (eColor[2] - sColor[2]) / step;
    for (var i = 0; i < step; i++) {
      if (i === step - 1) {
        gradientColorArr.push(endColor);
      } else {
        gradientColorArr.push(
          rgbToHex(
            parseInt(rStep * i + sColor[0]),
            parseInt(gStep * i + sColor[1]),
            parseInt(bStep * i + sColor[2])
          )
        );
      }
      // 计算每一步的hex值
    }
  }
  return gradientColorArr;
}
// 查找元素在数组中最近的下标
function findNearesttargetber(arr, target) {
  let mid;
  let l = 0;
  let r = arr.length - 1;
  while (r - l > 1) {
    mid = Math.floor((l + r) / 2);
    if (target < arr[mid]) {
      r = mid;
    } else {
      l = mid;
    }
  }
  return Math.abs(target - arr[l]) <= Math.abs(target - arr[r]) ? l : r;
}
// 十进制转rgba数组格式  hex2Rgba("#ffffff",0.5)   [255,255,255,0.5]
function hex2Rgba(hex, opacity) {
  if (!hex) return;
  let rgba =
    parseInt("0x" + hex.slice(1, 3)) +
    "," +
    parseInt("0x" + hex.slice(3, 5)) +
    "," +
    parseInt("0x" + hex.slice(5, 7)) +
    "," +
    (opacity || "1");
  return rgba.split(",");
  // return (
  //   "rgba(" +
  //   parseInt("0x" + hex.slice(1, 3)) +
  //   "," +
  //   parseInt("0x" + hex.slice(3, 5)) +
  //   "," +
  //   parseInt("0x" + hex.slice(5, 7)) +
  //   "," +
  //   (opacity || "1") +
  //   ")"
  // );
}
function quzhi(type) {
  let val = "";
  if (type == "qiye") {
    val = store.state.user.entId;
  } else if (type == "zf") {
    val = store.state.user.regioncode;
  } else {
    val = store.state.user.entId;
  }
  return val;
}
export default {
  vueScrollOps: vueScrollOps,
  niceScrollFun: niceScrollFun,
  loading: loading,
  startDateOps: startDateOps,
  dataZoomSlider: dataZoomSlider,
  monitoringPointOps: monitoringPointOps,
  echartsColor: echartsColor,
  changeTree: changeTree,
  changeListAll: changeListAll,
  fkRegionList: fkRegionList,
  fkRegionListCode: fkRegionListCode,
  fkRegionListAll: fkRegionListAll,
  getTreeRegionByParent: getTreeRegionByParent,
  changeTreeNew: changeTreeNew,
  changeDepartTree: changeDepartTree,
  fkGetRegion: fkGetRegion,
  jurisdiction: jurisdiction,
  jurisdictions: jurisdictions,
  getRiverTree: getRiverTree,
  findIndustrytype: findIndustrytype,
  departmentTree: departmentTree,
  envClsObj,
  getDistance,
  gradient,
  findNearesttargetber,
  hex2Rgba,
  quzhi
};
